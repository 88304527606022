button {
  cursor: pointer;
}

.room_container {
  width: 100%;
  height: 100vh;
  display: flex;
  /* background: #a1a1a1; */
  background: #242424;
}

.chat_section_container {
  height: 100%;
  width: 20%;
  /* background-color:#a1a1a1; */
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;

}

.chat_label_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.chat_label_paragraph {

  font-weight: 400;
  /* color: #1e1f1f; */
  font-size: 16px;

}

.new_message_container {

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
}

.new_message_input {
  border: 1px solid #d1dbe2;
  border-radius: 60px;
  padding: 0 50px 0px 20px;
  height: 55px;
  font-size: 16px;
  width: 80%;
  box-shadow: 2px 20px 5px rgba(0, 82, 201, 0.05);
  background: #dadce0;
  margin: 0px 0px 6px 8px;
}

.new_message_input::placeholder {
  color: #3c4043;
}

.new_message_textarea:focus {
  outline: none;
}

.new_message_button {
  position: absolute;
  right: 10%;
}

.messages_container {
  /* width: calc(100% - 70px);
  margin-left: 30px;
  margin-right: 30px; */
  /* height: 76.5vh; */
  flex-grow: 1;
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
}

.message_container {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.message_align_left {
  align-items: flex-start;
}

.message_align_right {
  align-items: flex-end;
}

.message_left_styles {
  background: rgba(0, 82, 201, 0.1);
  color: black;
}

.message_right_styles {
  background: linear-gradient(168.68deg, #0052c9 1.12%, #0a91db 100%);
  color: white;
}

.message_title {
  margin: 0 0 10px 0;
  padding: 0 10px;
  color: black;
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.message_content {
  margin: 0px 10px;
  padding: 10px 30px 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  background: rgba(0, 188, 164, 0.1);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 10px;
}

.overlay_container {
  width: 100%;
  height: 100vh;
  background: rgba(0, 82, 201, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
  backdrop-filter: blur(10px);
  background: transparent;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #fad133;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.cssloader {
  position: relative;
  z-index: 999;
  left: 3%;
}

.cssloader img {
  max-width: 270px;
}

.Mleftboxtext {
  color: #171717;
  font-size: 16px;
  font-family: 'Inter Regular';
  line-height: 140%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.participants_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-content: flex-start;
  height: 50%;
}

.participants_paragraph {
  color: black;
  text-align: start;
  font-weight: 500;
  margin-left: 40px;
  transition: 0.5s;
  margin: 0 40px;
  padding: 10px;
  border: 2px solid #e4e4e4;
  border-radius: 10px;
}

.participants_paragraph:hover {
  background-color: #feeeb3;
  border-radius: 8px;
  border: 2px solid #fad133;
}

.participants_paragraph span {

  margin-right: 10px;

}

.participants_separator_line {
  width: calc(100% - 80px);
  height: 2px;
  background-color: #e5e5e5;
  margin-left: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.participants_section_container {
  height: 100%;
  width: 20%;
  /* background-color: #a1a1a1; */
  display: flex;
  flex-direction: column;

}

.participation-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: snow;
  border-radius: 15px;
  height: 100%;
  margin: 15px 10px;
}

.chat-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: snow;
  border-radius: 15px;
  height: 100%;
  margin: 15px 10px;
  position: relative;
}

.participants_label_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.participants_label_paragraph {
  font-weight: 400;
  /* color: #9ca5ab; */
  /* margin-left: 40px; */
  font-size: 16px;
}

.local_screen_share_preview {
  position: absolute;
  width: 13%;
  height: 88%;
  background-color: black;
  border-radius: 8px;
  border: 2px solid blue;
  left: 3%;
  bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_buttons_container {
  z-index: 99;
  width: 50%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 24%;
  bottom: 6px;
  background: #565656;
  /* border-top-left-radius: 25px; */
  /* border-top-right-radius: 25px; */
  border-radius: 25px;
}

.video_button_container {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
}

.video_button_image {
  height: 80%;
  padding: 10px;
  border-radius: 25px;
  transition: 0.3s;
}

.video_button_image:hover {
  background: #fad133;
  padding: 10px;
  border-radius: 25px;
  /* opacity: 0.5; */
}

.video_button_image-active {
  height: 80%;
  padding: 10px;
  border-radius: 25px;
  background: #fad133;
  /* opacity: 0.5; */
}


.video_button_end {
  width: 60px;
  height: 60px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 65px;
  border: none;
  color: white;
  background: #fc5d5b;
  box-shadow: 0px 3px 30px rgba(252, 93, 91, 0.1);
  transition: 0.2s;
}

.video_button_end:hover {
  background: red;
}

.videos_container {
  height: 90%;
  width: 100%;
  background-color: #f6f9fb;
  position: relative;
  border-radius: 0px 0px 25px 25px;
}

.participants {
  display: flex;
  width: 100%;
  height: 100%;
}

.participant {
  flex-grow: 1;
}

/* .identity {
        position: absolute;
        left: 0px;
        bottom: 15px;
        color: white;
        padding-left: 15px;
        box-sizing: border-box;
        z-index: 10;
      } */

.video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.screen_share_track {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}

.room {
  width: 100%;
  height: 100%;
}

video {
  object-fit: cover;
  /* object-fit: contain; */
  height: 100%;
  width: 100%;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

.room_label {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 4;
}

.room_label_paragraph {
  align-items: center;
  font-size: 17px;
  color: snow;
  margin-top: 0;
  padding: 18px 38px;
  background: #565656;
  /* opacity: 0.9; */
  display: flex;
  border-radius: 15px;
}

.room_label_paragraph button {
  background: none;
  border: none;
}

.video_track_container {
  margin-top: 10px;
}

.video_track_container:first-child:nth-last-child(1) {
  width: 822px;
  /* height: 100%; */
  position: relative;

}

.video_track_container:first-child:nth-last-child(2),
.video_track_container:first-child:nth-last-child(2)~.video_track_container {
  width: 50%;
  height: 50%;
  position: relative;
  /* transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg); */
  /* transform: rotateY(180deg); */
}

.video_track_container video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.flipped {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  transform: none !important;
}

video.flipped {
  object-fit: contain;
}

.video_track_container:first-child:nth-last-child(3),
.video_track_container:first-child:nth-last-child(3)~.video_track_container {
  width: 50%;
  height: 50%;
  position: relative;
  /* transform: rotateY(180deg); */
}

.video_track_container:first-child:nth-last-child(4),
.video_track_container:first-child:nth-last-child(4)~.video_track_container {
  width: 50%;
  height: 50%;
  position: relative;
  /* transform: rotateY(180deg); */


}

.video_track_container:first-child:nth-last-child(5),
.video_track_container:first-child:nth-last-child(5)~.video_track_container {
  width: 33%;
  height: 33%;
  position: relative;
  /* transform: rotateY(180deg); */

}

.video_track_container:first-child:nth-last-child(6),
.video_track_container:first-child:nth-last-child(6)~.video_track_container {
  width: 33%;
  height: 33%;
  /* transform: rotateY(180deg); */

}

.video_track_container:first-child:nth-last-child(7),
.video_track_container:first-child:nth-last-child(7)~.video_track_container {
  width: 33%;
  height: 33%;
  /* transform: rotateY(180deg); */

}

.video_track_container:first-child:nth-last-child(8),
.video_track_container:first-child:nth-last-child(8)~.video_track_container {
  width: 33%;
  height: 33%;
  /* transform: rotateY(180deg); */

}

.video_track_container:first-child:nth-last-child(9),
.video_track_container:first-child:nth-last-child(9)~.video_track_container {
  width: 33%;
  height: 33%;
  /* transform: rotateY(180deg); */

}

.video_section_container {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.meetingVideoArea .full_screen {
  position: absolute;
  top: 0%;
  left: 0%;
  width: calc(100% - 330px) !important;
  height: calc(100% - 85px) !important;
  left: 0;
  top: 0;
  margin: 0;
  border-radius: 0;

}


.label_only_audio_container {
  width: 100%;
  height: 82%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 13%;
  left: 0%;
  /* border: 1px solid white; */
  border-radius: 10px;
}

.label_only_audio_container {
  font-size: 18px;
  color: white;
}

.direct_chat_container {
  height: 45%;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.direct_chat_header {
  height: 50px;
  background: linear-gradient(168.68deg, #0052c9 1.12%, #0a91db 100%);
  width: 95%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.direct_chat_header_paragraph {
  color: white;
  font-size: 16px;
}

.new_message_direct_border {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  width: 95%;
}

.direct_messages_container {
  flex-grow: 1;
  width: 95%;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.conversation_not_chosen_overlay {
  width: 95%;
  height: 86%;
  bottom: 0;
  left: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  position: absolute;
}

.conversation_not_chosen_overlay_text {
  font-size: 16px;
  font-weight: 700;
  color: black;
  margin-bottom: 80px;
}

.direct_message_container_author {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.direct_message_container_receiver {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.author_direct_message {
  background: linear-gradient(168.68deg, #0052c9 1.12%, #0a91db 100%);
  float: right;
  border-radius: 8px 8px 0px 8px;
  padding: 13px;
  margin-right: 5px;
  color: white;
  display: inline;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  margin-left: 5px;
}

.receiver_direct_message {
  border-radius: 8px 8px 8px 0px;
  border: 1px solid #d5deeb;
  background: #ffffff;
  padding: 13px;
  float: left;
  margin-left: 5px;
  display: inline;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  margin-right: 5px;
}

.more-dropdown {
  position: relative;
}

.more-options {
  position: absolute;
  width: 179px;
  background: #ffff;
  border: 1px solid #fad133;
  bottom: 81px;
  z-index: 99;
  border-radius: 8px;
  padding: 10px;

}

.more-options ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.more-options ul li {
  padding: 10px;
  /* border-bottom: 1px solid rgba(0,0,0,0.1); */
  border: 1px solid transparent;
  display: flex;
  align-items: center;
}

.more-options ul li svg {
  margin-right: 5px;
}

.more-options ul li:hover {
  border: 1px solid #fad133;
  background: #feeeb3;
  border-radius: 8px;
}


.userVideoBox:first-child {
  width: 100px;
}

.logo {
  max-width: 150px;
  max-height: 30px;
  height: 30PX;
  object-fit: contain;
}

.userVideoZoom {
  position: absolute;
  width: 100%;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  z-index: 999;
}

.userVideoZoom .fullIconWrap {
  max-width: 30px;
  cursor: pointer;
  background: rgb(0 0 0 / 50%);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}




.userVideoZoom img {
  max-width: 20px;
  filter: invert(1);
}

/* New One */
.textdismis .alertimg {
  width: 20px;
  height: 20px;
  /* filter: invert(1); */
}

.btndismis .btn {
  padding: 4px 8px;
  background: transparent;
  color: #525252;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #525252;
  cursor: pointer;
  font-size: 12px;
font-weight: 500;
line-height: 120%; 
}

.poupdeletem{
  position: absolute;
  top: -100%;
  overflow: hidden;
  transition: 0.4s;
  left: 0;
  z-index: 9;
  opacity: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dismissmain {
  background: #FFCD42;
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
  border-radius: 6px;
}

.poupdeletem.active {
  top: 5%;
  opacity: 1;
}

.dismissmain .btndismis {
  width: fit-content;
}

.dismissmain .textdismis {
  color: #171717;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
font-weight: 500;
line-height: 120%
  /* flex: 1; */
}

.Mheaderbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  border-left: 1px solid #D4D4D4;
  height: 100%;
  padding-left: 20px;
  margin-left: 20px;
  gap: 40px;
}

.Mrightboxbtn {
  display: flex;
  align-items: center;
  gap: 10px;

}

.Mrightboxbtn .RedFillButton {
  font-size: 16px;
  padding: 10px 20px;
  font-weight: 500;
  min-width: fit-content;
}

/* Tooltip code */
.lineclamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}

.tooltipnew .lineclamp {
  width: 94%;
}

.textagendadata {
  width: 100%;
  overflow: hidden;
  min-width: 200px;
}

.tooltip-scroll {
  position: relative;
}

.toolwrappertext {
  position: relative;
  max-width: 176px;
  width: 100%;
}

.tooltipnew {
  transform: none;
}

.tooltipnew:hover>.tooltip-text,
.tooltipnew:hover>.toolwrappertext {
  pointer-events: auto;
  opacity: 1.0;
}

.tooltipnew>.tooltip-text,
.tooltipnew>.toolwrappertext {
  display: block;
  position: absolute;
  z-index: 6000;
  overflow: visible;
  padding: 4px 8px;
  margin-top: 10px;
  border-radius: 4px;
  text-align: left;
  background: var(--black-1);
  pointer-events: none;
  opacity: 0.0;
  transition: all 0.3s ease-out;
  width: 100%;
  min-width: 176px;
  top: 22px;
  right: 10px;
}

.tooltipnew>.tooltip-text:before,
.tooltipnew>.toolwrappertext:before {
  display: inline;
  content: "";
  position: absolute;
  border: solid;
  border-color: rgba(0, 0, 0, 1) transparent;
  border-width: 0 0.5em 0.5em 0.5em;
  z-index: 6000;
  left: 46%;
  top: -8px;
  transform: rotate(0deg);
}

.toolwrappertext>.tooltip-text {
  overflow-y: auto;
  max-height: 140px;
  display: block;
  line-height: 120%;
  font-size: 14px;
  font-weight: 400;
  color: var(--white);

}

.MBtnLeave .leave path{
  fill: red;
}

.MleaveMeeting{
  display: none;
}

.headerLeavebtn{
  min-width: fit-content;
}

.meetingButtons button{
  cursor: pointer;
}

.loadingtext{
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  gap: 2px;
}

.loadingdots{
  display: flex;
  align-items: flex-end;
  gap: 2px;
}

.loadingdots span{
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(  --primary-5);
  display: flex;
  animation: dotsload 1s infinite linear ;
}

@keyframes dotsload{
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}

/* Tooltip code End */

@media only screen and (max-width:540px) {

  .chatBoxOuter {
    position: absolute;
    width: 100%;
    background: var(--white);
    right: 0;
    padding: 10px 0;
    visibility: hidden;
    opacity: 0;
    top: 71px;
    transform: translateY(15px);
    transition: 0.5s;
    height: 455px;
  }

  .meetingPageContainer {
    width: 100% !important;
  }

  .chatBoxOuter.active {
    width: 100% !important;
    height: calc(100vh - 73px);
    padding: 20px;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: 0.5s;
    z-index: 99;
  }

  .meetingVideoArea {
    padding: 10px !important;
  }

  .meetingVideoArea .userVideo {
    width: 30%;
    height: 25%;
  }

  .meetingFooter {
    padding: var(--spacing-20px) var(--spacing-8px) !important;
  }

  .meetingPageHeader {
    padding: var(--spacing-20px) var(--spacing-8px) !important;
  }

  .chatSection {
    height: calc(100vh - 300px) !important;
  }
}


@media (max-width: 767px){
  .MfrontpageView.introductionPageContainer{
    flex-direction: column-reverse;
  }

  .introductionPageContentBox .Mtitletext {
    margin-bottom: 20px;
  }

  .textidds{
    font-size: 14px;
  }

  .MleaveMeeting{
    display: block;
  }
  
  .Mheaderbox .Mleftboxtext{
    display: none;
  }

  .headerLeavebtn{
    display: none;
  }

  .dismissmain .textdismis{
    font-size: 14px;
  }

  .btndismis .btn{
    padding: 8px 16px;
  }

  .dismissmain{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

}