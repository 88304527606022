.join_room_page_container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.join_room_page_panel {
  width: 400px;
  height: 400px;
  background-color: white;
  border: 1px solid grey;
  filter: drop-shadow(0 0 0.2rem grey);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 5px 5px #fad133;
}

.join_room_title {
  font-size: 24px;
  font-weight: 700;
  margin-left: 35px;
  margin-top: 50px;
}

.join_room_inputs_container {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}

.error_message_container {
  display: flex;
  height: 50px;
  margin-left: 35px;
}

.newErrorMessage {
  color: var(--danger-6);
  font-size: 14px;
  line-height: 120%;
  margin-left: 0;
  height: auto;
}

.textBlue p {
  color: var(--primary-5) !important;
}

.error_message_paragraph {
  color: red;
  font-size: 14px;
  font-family: 'Inter Regular';
  font-weight: 400;
}

.loading_overlay_container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 82, 201, 0.7);
}

.loading_overlay_loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #0052c9;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.checkbox_container {
  display: flex;
  margin-left: 35px;
  margin-top: 5px;
  align-items: center;
}

.checkbox_connection {
  width: 20px;
  height: 20px;
  background: #fad133;
  /* background: #9f9f9f; */
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  backdrop-filter: blur(80px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox_container_paragraph {
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
}

.checkbox_image {
  max-width: 100%;
  max-height: 100%;
}

.join_room_buttons_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.join_room_cancel_button {
  width: 80px;
  margin-right: 35px;
  height: 40px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 8px;
  transition: 0.3s;
}

.join_room_cancel_button:hover {
  background-color: #e5e5e5;
}

.join_room_success_button {
  width: 80px;
  margin-right: 15px;
  height: 40px;
  background-color: #fad133;
  border-radius: 8px;
  border: none;
  font-weight: 700;
  color: white;
  transition: 0.3s;
}

.join_room_success_button:hover {
  background-color: #e5e5e5;
  color: black;
}

.join_room_inputs_container {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}

.primary-btn {
  display: none;
}

.join_room_input {
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  width: 300px;
  height: 40px;
  padding: 0px 15px;
}

.join_room_input:focus {
  border: 1px solid blue;
}

.nameInput .join_room_input {
  height: 50px;
  width: 100%;
}

.nameInput .join_room_input:focus-visible {
  outline: unset;
}

.nameInput .join_room_input:focus {
  border: 1px solid transparent;
}

/* New Meeting Screen */
.MfrontpageView.introductionPageContainer {
  gap: 40px;
}

.MfrontpageView.introductionPageContainer .primaryBtn {
  margin-top: 40px;
}

.MfrontpageView.introductionPageContainer .primaryBtn:hover{
  box-shadow: none;
}

.MfrontpageView .primaryBtn.flexbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 500;
}

.MfrontpageView .primaryBtn.flexbtn svg {
  transition: 0.4s;
}

.MfrontpageView .primaryBtn.flexbtn:hover svg {
  transform: translateX(5px);
}

.Mtitletext {
  color: #000;
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
  font-family: 'Inter Regular';
}

.MmeetingDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
}

.textId {
  color: #737373;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  font-family: 'Inter Regular';
}

.textidds {
  color: #171717;
  font-family: 'Inter Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
}


.MfrontpageView .error_message_container {
  height: auto;
  margin-top: 10px;
  margin-left: 0;
}

/* New Meeting Screen */

@media only screen and (max-width:540px) {
  .primary-btn {
    display: block;
    border: none;
    background: transparent;
  }

  .appContainer {
    max-width: 100%;
    width: 100%;

  }

  .introductionPageContainer {
    flex-direction: column-reverse;
    gap: 20px;

    padding: 10px 0;
  }

  .userVideo {
    width: 330px;
  }

  .introductionPageContentBox {
    width: 330px;
  }

  .introductionPageContentBox .formGroup {
    margin-top: var(--spacing-28px);
  }

  .introductionPageContentBox .primaryBtn {
    margin-top: 30px;
  }

  .error_message_container {
    margin-left: 0;
  }



}