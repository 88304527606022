
/* Spinner default */

.loader--default {
    display: inline-block;
    position: relative;
    height: 64px;
    width: 64px;
}

.loader--default div {
    position: absolute;
    animation: loader--default 1.2s linear infinite;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    background: #3397EF;
}

.loader--default div:nth-child(1) {
    animation-delay: 0s;
    top: 29px;
    left: 53px;
}

.loader--default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 18px;
    left: 50px;
}

.loader--default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 9px;
    left: 41px;
}

.loader--default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 6px;
    left: 29px;
}

.loader--default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 9px;
    left: 18px;
}

.loader--default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 18px;
    left: 9px;
}

.loader--default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 29px;
    left: 6px;
}

.loader--default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 41px;
    left: 9px;
}

.loader--default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 50px;
    left: 18px;
}

.loader--default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 53px;
    left: 29px;
}

.loader--default div:nth-child(11) {
    animation-delay: -1s;
    top: 50px;
    left: 41px;
}

.loader--default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 41px;
    left: 50px;
}

.FormBtnAddon2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.FormBtnAddon2 .endCallText
{
    font-size: 14px;
}

@keyframes loader--default {
    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}