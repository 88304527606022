.button{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 15px 12px;
    border: 1px solid transparent;
    background: transparent;
    color: var(--black3);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    transition: 0.4s ease-in;
    /* width: 100%; */
    display: inline-block;
}

.button img{
    max-width: 14px;
}

.iconButton{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 4px;
}

.BlueBorderButton{
    border: 1px solid var(--primary-5);
    color: var(--primary-5);
}

.BlueFillButton{
    border: 1px solid var(--primary-5);
    background: var(--primary-5);
    color: var(--white);
}

.DarkblueFillButton{
    border: 1px solid var(--primary-5);
    background: var(--primary-5);
    color: var(--white);
}

.GreyFillButton{
    border: 1px solid var(--grey-2);
    background: var(--grey-4);
    color: var(--black-5);
}

.GreyBorderButton{
    border: 1px solid var(--grey-2);
    background: var(--white);
    color: var(--black);
}

.BlackFillButton{
    border: 1px solid var(--black-1);
    background: var(--black-1);
    color: var(--white);
}

.RedFillButton{
    border: 1px solid var(--danger-5);
    background: var(--danger-5);
    color: var(--white);
}

.RedBorderButton{
    border: 1px solid var(--danger-5);
    background: var(--white);
    color: var(--danger-5);;
}

.BlackBorderButton{
    border: 1px solid var(--black);
    background: var(--white);
    color: var(--black);
}

.GreenFillButton{
    border: 1px solid var(--green);
    background: var(--green);
    color: var(--white);
}

.invertImg img{
    filter: invert(1);
}

.blueTextButton{
    color: var(--primary-5);
}

.reverseIcon{
    flex-direction: row-reverse;
}

.loginButton{
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-top: 40px;
    padding: 15px;
}

.iconReversed{
    flex-direction: row-reverse;
}

.BlueFillButton .overLapLoad{
    background: var(--primary-5);
}

.RedFillButton .overLapLoad{
    background: var(--danger-5);
}

.overLapLoad{
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: 0.4s ease-in;
    z-index: -1;
    opacity: 0;
}

.overLapLoad.true{
    z-index: 9;
    opacity: 1;
}

.overLapLoad::after{
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    position: absolute;
    left: calc(50% - 0.75em);
    top: calc(50% - 0.75em);
    border: 0.15em solid transparent;
    border-right-color: white;
    border-radius: 50%;
    animation: button-anim 0.7s linear infinite;
}

@keyframes button-anim {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
}