.popupWrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0,0,0,0.2);
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    opacity: 0;
}

.popupWrapper.active{
    z-index: 999;
    opacity: 1;
}

.popupContainer{
    width: 600px;
    margin: auto;
    background: var(--white);
    border: 1px solid #EEEEEE;
    border-radius: 15px;
    overflow: hidden;
}

.popupHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #EEEEEE;
}

.popupBody{
    padding: 20px;
    max-height: 500px;
    overflow: auto;
}

.TwoSections{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.flexend{
    justify-content: flex-end;
}

.popupFooter{
    padding: 20px;
    padding-top: 0;
}

.popupFooter button{
    font-size: 14px;
    padding: 10px 12px;
}

.buttonWrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.close{
    display: flex;
    align-items: center;
    padding: 2px;
    border: 1px solid var(--grey2);
    border-radius: 5px;
    cursor: pointer;
}

.endCallPopup .popupHeader{
    justify-content: flex-end;
    padding-bottom: 0;
    border-bottom: 1px solid #EEEEEE;
}

.endCallPopup .popupBody{
    padding-top: 20px;
    padding-bottom: 20px;
}

.popupWrapper.endCallPopup .popupContainer{
    max-width: 350px;
    width: 100%;
}

.endCallText{
    font-size: 16px;
    line-height: 150%;
    font-weight: 600;
}

.FormBtnAddon{
    margin-bottom: 20px;
}